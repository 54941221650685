<template>
<div class="header">
    <v-container id="wrapper">
        <v-row>

            <!-- Home/Logo -->
            <div class='homeLogo'>
                <img :src="images.logo" @click="$router.push({name:'Home'})"/>
            </div>

            <!-- DESKTOP ONLY
            shows: Contact Us, Socials, Search, Donate Now, Login/Initials Menu -->
            <template v-if="$store.getters.width >= 960">
            <!-- Contact, Social, & Search -->
            <div class='wrap_contact-socials-search'>
                <div class='wrap_contact-socials'>
                    <!-- Contact Us -->
                    <v-btn class='contactUs' link text href="/about_us/contact" @click.prevent="$router.push({path:'/about_us/contact'})">Contact Us </v-btn>
                    <!-- Socials -->
                    <div class="socials">
                        <template v-for="(icon, iconIndex) in images.icons">
                            <a :href="icon[1]" :key="`socialIcon-${iconIndex}`" :target="icon[1].indexOf('mailto:')==0 ? '' : '_blank'">
                                <svg :data-src="`${$store.getters.bucket}/${icon[0].Key}`" :key="'header-icon-'+iconIndex" />
                            </a>
                        </template>
                    </div>
                </div>
                <!-- Search -->
                <SearchBar class='SearchBar' style="position: relative; top: -4px;"/>
            </div>
            </template>

            <!-- Donate & Initials Menu -->
            <div class='wrap_donate-login-initialsMenu'>
                <!-- DONATE NOW -->
                <v-btn class='donateButton button-theme red' elevation="0" @click="$router.push({path:'/get_involved/donate'})">DONATE NOW</v-btn>
                <!-- Template for LOG IN and Initials Menu -->
                <template v-if="$store.getters.Authorizer">
                <!-- LOG IN button -->
                <v-btn v-if="!$store.getters.user" class="loginButton button-theme" elevation="0" @click="$router.push({path:'/sign_in'})">LOG IN</v-btn>
                <!-- Initials Menu -->
                <v-menu v-else offset-y>
                    <!-- Initials Button -->
                    <template v-slot:activator="{on, attrs}">
                    <v-btn class='initialsButton' large icon v-bind="attrs" v-on="on">
                        <v-avatar>
                            {{$store.getters.user.attributes.given_name.substr(0,1)}}{{$store.getters.user.attributes.family_name.substr(0,1)}}
                        </v-avatar>
                    </v-btn>
                    </template>
                    <!-- Inside Initials Menu -->
                    <v-list>
                        <!-- PINS -->
                        <v-list-item>
                            <v-icon>mdi-pin</v-icon>
                            <v-btn text @click="$router.push({path:'/account/bookmarks'})">PINS</v-btn>
                        </v-list-item>
                        <!-- MY ACCOUNT -->
                        <v-list-item>
                            <v-icon>mdi-account</v-icon>
                            <v-btn text @click="$router.push({path:'/account/profile'})">My Account</v-btn>
                        </v-list-item>

                        <!-- <v-list-item>
                            <v-btn text @click="$router.push({path:'/account/preferences'})">My Account</v-btn>
                        </v-list-item> -->

                        <!-- ADMIN PANEL -->
                        <template v-if="allowAccess">
                        <v-list-item>
                            <v-icon>mdi-account-tie-hat</v-icon>
                            <v-btn text @click="$router.push({path:'/admin'})">Admin Panel</v-btn>
                        </v-list-item>
                        </template>
                        <!-- SIGN OUT -->
                        <v-list-item>
                            <v-icon>mdi-exit-to-app</v-icon>
                            <v-btn text @click="$store.getters.Authorizer.signOut">Sign Out</v-btn>
                        </v-list-item>
                    </v-list>
                    <!-- END Inside Initials Menu -->
                </v-menu>
                <!-- END Initials Menu -->
                </template>
            </div>
            <!-- END Donate & Initials Menu -->

            <!-- TABLET ONLY
            Hamburger Menu:
                -> Search, Donate Now
                -> Main Nav items
                -> Contact Us, Disclaimer, Privacy Policy
                -> Pins, My Account, Admin Panel, Sign-out -->
            <template v-if="$store.getters.width < 960">
            <div class='hamburgerMenu' :class="{loggedIn:$store.getters.user, selected:this.$store.getters.mainNavOpen==true}" @click="mainNavOpen()">
                <v-icon v-if="this.$store.getters.mainNavOpen==false" color="white">mdi-menu</v-icon>
                <v-icon v-if="this.$store.getters.mainNavOpen==true" color="white">mdi-close</v-icon>
                <p>Menu</p>
            </div>
            </template>

        </v-row>
    </v-container>
</div>
</template>

<script>
import Logo from '@/assets/logo.png'
import SearchBar from '@/components/Search/searchBar'

export default {
    components: {
        SearchBar
    },
    methods: {
        mainNavOpen: function(){
            if(this.$store.getters.mainNavOpen == true){
                this.$store.commit('mainNavOpen', false);
            }
            else{
                this.$store.commit('mainNavOpen', true);
            };
        },
        share: function(){
            let path = this.$route.fullPath
            let text = this.$route.meta.label[this.$store.getters.language]
            let link = `http://twitter.com/share?text=${text}&url=https://thrombosiscanada.ca${path}`
            return link
        }
    },
    computed: {
        allowAccess: function(){
            let allowed = ['SuperAdmin','admin','staff','tester']
            let payload = this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups']
            if( payload){
                return payload.filter((item)=>{return allowed.includes(item)}).length>0
            }
            // this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups'].includes('SuperAdmin')
        },
        images: function(){
            let assets = this.$store.getters.assets.map
            let icons = [
                [ assets['uploads/icons/ic-bluesky.svg'], 'https://bsky.app/profile/thrombosiscan.bsky.social'],
                [ assets['uploads/icons/twitter.svg'], 'https://twitter.com/ThrombosisCan?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor'],
                //[ assets['uploads/icons/twitter.svg'], this.share()],
                [ assets['uploads/icons/email.svg'], `mailto:${this.$store.getters.contact.email}`],
                [ assets['uploads/icons/youtube.svg'], 'https://www.youtube.com/thrombosiscanada'],
                [ assets['uploads/icons/linkedIn.svg'], 'https://ca.linkedin.com/company/thrombosis-canada'],
                [ assets['uploads/icons/facebook.svg'], 'https://www.facebook.com/ThrombosisCan/']
            ]
            return {
                logo: Logo,
                icons
            }
        }
    }
}
</script>

<style scoped lang="scss">

.header{
    border-top: 3px solid $primary;
    border-bottom: 3px solid $primary;

    > .container {
        position: relative;
        padding-top: 24px;
        min-height: 104px;
        max-width: 91% !important;
        margin-left: unset;
        margin-right: unset;
        
        @media(max-width: 959px){
            min-height: 96px;
        }
        @media(max-width: 599px){
            min-height: 64px;
        }

        .homeLogo {
            cursor: pointer;
            width: 280px;
            margin-left: 25px;
            
            @media(max-width: 959px){
                width: 224px;
                margin-top: 8px;
                margin-left: 8px;
            }
            @media(max-width: 599px){
                width: 144px;
                margin-top: 6px;
                margin-left: 4px;
            }

            img {
                width: 100%;
            }
        }

        .wrap_contact-socials-search {
            position: absolute;
            right: 136px;

            .wrap_contact-socials {
                display: flex;

                .contactUs {
                    width: auto;
                }

                .socials {
                    cursor: pointer;
                    padding-top: 2px;

                    svg{
                        display: inline-block;
                        width: 24px;
                        height: 16px;
                        margin: 8px;
                        fill: $primary;

                        &:hover {
                            opacity: 0.9;
                        }
                    }
                }
            }

            .SearchBar {
                margin-top: -10px;
            }
        }

        .wrap_donate-login-initialsMenu {
            display: flex;
            flex-flow: column;
            position: absolute;
            right: 0;
            
            @media(max-width: 959px){
                right: 8px;
            }

            .donateButton {
                @media(max-width: 599px){
                    display: none;
                }
            }

            .loginButton {
                @media(max-width: 599px){
                    width: 72px;
                    margin-top: 8px;
                    margin-right: -4px;
                }
            }

            .initialsButton {
                background-color: #293172;
                color: white;
                margin: 0 auto;
                width: 36px;
                height: 36px;
                @media(max-width: 599px){
                    margin-top: 8px;
                }
            }
        }

        .hamburgerMenu {
            display: flex;
            align-items: center;
            position: absolute;
            right: 128px;
            padding: 0 24px;
            height: 77px;
            background-color: #293172;
            color: white;
            border-radius: 5px;
            font-size: 24px;
            font-weight: bold;
            cursor: pointer;

            @media(max-width: 959px){
                right: 132px;
            }
            @media(max-width: 599px){
                right: 76px;
                font-size: 16px;
                height: 36px;
                margin-top: 8px;
                margin-right: 4px;
                padding: 0 8px;
            }

            &.loggedIn {
                @media(max-width: 599px){
                    right: 48px;
                }
            }

            &.selected {
                background-color: $primary-light-2;
            }

            &:hover {
                opacity: 0.9;
            }

            .v-icon {
                color: white !important;
                font-size: 40px;
            }

            p {
                margin-top: 16px;
                margin-left: 8px;
                @media(max-width: 599px){
                    display: none;
                }
            }
        }
    }
}

.button-theme{
    width: 120px !important;
    background-color: $primary !important;
    color: white !important;
    font-weight: bold;
    @media(max-width: 599px){
        width: 72px !important;
    }
}

.button-theme.red{
    background-color: $tc-red !important;
    margin-bottom: 4px;
}


</style>
