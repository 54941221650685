<template>
  <div>
    <v-btn v-if="allow" fab dark class="language-toggle-button" @click="toggle" :class="{ 'custom-small': $vuetify.breakpoint.width < 600 }">
      {{ui.language=='en-ca' ? 'fr' : 'en'}}
    </v-btn>
  </div>
</template>

<script>
export default {
    props: {
        page: {
            type: Object,
            required: true
        }
    },
    mounted: function(){
      this.init()
    },    
    data: function(){
      return {
        ui: {
          language: 'en-ca'
        }
      }
    },
    methods: {
      init: function(){
        // console.log('pageLanguage',this.page)
        let queryLanguage = this._queryLanguage
        if(queryLanguage && queryLanguage!=this.ui.language){
          this.toggle()
        }
      },
      toggle: function(){
        this.ui.language = (this.ui.language=='en-ca' ? 'fr-ca' : 'en-ca')
        // console.log('page language toggle', this.ui.language)
        this.$emit('language',this.ui.language)
      }    
    },
    computed: {
      allow: function(){
        return this.page.enableFrench === true
      },
      _queryLanguage: function(){
        return this.$route.query.language
      }     
    },
    watch: {
      definition: {
        deep: true,
        handler: function(){
          this.init()
        }
      },
      allow: function(){
        let allow = this.allow
        if(!allow && this.ui.language=='fr-ca'){
          this.toggle()
        }
      },
      _queryLanguage: function(){
        this.init()
      }
    }
}
</script>

<style scoped lang="scss">   
.language-toggle-button {
    background-color: $tc-red !important;
    position: fixed;
    top: 23px;
    left: 93%;
    z-index: 5000;
    
    @media(min-width: 320px){
        left: unset;
        right: 0px;
    }
    
    @media(min-width: 325px){
        left: unset;
        right: 0px;
    }
    
    @media(min-width: 330px){
        left: unset;
        right: 0px;
    }    
    
    @media(min-width: 335px){
        left: unset;
        right: 0px;
    }
    
    @media(min-width: 340px){
        left: unset;
        right: 3px;
    }
    
    @media(min-width: 600px){
        left: unset;
        right: 4px;
    }
    
    @media(min-width: 768px){
        left: unset;
        right: 2%;
    }  
    
    @media(min-width: 1600px){
        left: 93%;
        right: unset;
    }
       
    @media(min-width: 1680px){
        left: 90%;
    }
    
    @media(min-width: 1800px){
        left: 88%;
    }
    
    @media(min-width: 1900px){
        left: 86%;
    }
}

.custom-small {
    width: 36px !important;
    height: 36px !important;
}
</style>