<template>
  <div class="clinicalGuide">
    <div style="width: 100%; text-align: right;">
        <!-- <v-btn depressed @click="makePDF" class="themed-button-dark" elevation="0">
            Download PDF <v-icon right>
                mdi-download-circle-outline
            </v-icon>
        </v-btn> -->
        <v-btn icon link class="share" :href="shareFaceBook(guide)" target="_blank">
                <v-icon color="white" medium>mdi-facebook</v-icon>
        </v-btn>

        <v-btn icon link class="share" :href="shareTwitter(guide)" target="_blank">
            <v-icon color="white" medium>mdi-twitter</v-icon>
        </v-btn>
        
        <v-btn icon class="pin" style="margin-left: 8px;" :class="[{'pinned':pins.isPinned(pinKey,guide)}]" @click="pins.pin(pinKey,guide);$forceUpdate()">
          <v-icon color="white" medium>mdi-pin</v-icon>
        </v-btn>        
        <v-btn depressed link :href="`${$store.getters.bucket}/clinical_guides/pdfs/${guide.id}_${guide.version}.pdf`" target="_blank" class="themed-button-dark" elevation="0">
            Download PDF <v-icon right>
                mdi-download-circle-outline
            </v-icon>
        </v-btn>
    </div>
    <div v-html="rewriteGuideLink(guide.html)"/>
    <sub v-if="debug" v-html="guide.meta.version"/>
  </div>
</template>

<script>
import pinsController from '@/plugins/pins'
import Logo from '@/assets/logo.png'
export default {
    props: {
        guide: {
            type: Object,
            required: true
        },
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        generatePDF: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    created: function(){
        let self = this
        // this.pins = new pinsController('clinicalGuides', this)
        this.pins = this.$store.getters.controllers.pins
        this.track(this.guide)
        setTimeout(function(){
            self.correctImages()
            if(self.generatePDF){
                self.makePDF()
            }
        },200)
    },
    mounted: function(){
        let self = this
        setTimeout(() => {
            self.init()
        }, 200);
    },
    data: function(){
        return {
            pins: null,
            pinKey: 'clinicalGuides'
        }
    },
    methods: {
        getGuideLanguageById(guideID=null){
            for (let languageIdx in this.$store.getters.clinicalGuideData){
                let languageGuides = this.$store.getters.clinicalGuideData[languageIdx].guides
                let found = languageGuides.filter(guide => guide.id==guideID)
                if (found.length>0){
                    if (found[0].language==='english'){
                        return "en-ca"
                    }
                    else if (found[0].language==='french'){
                        return "fr-ca"
                    }
                }
            }
            
            return "en-ca"
        },
        
        rewriteGuideLink(html, pdfUsage=false){

            function composeHrefLinkToReplaceWith(jscriptExpression){
                //let haystack = "javascript:openLinkedGuide('80')"
                //let regexFindBeweenBrackets = /\(([^)]+)\)/g
                //let searchNeedleUsingRegex = [...haystack.match(regexFindBeweenBrackets)]
                
                const regexFindBeweenBrackets = /\(([^)]+)\)/g
                let searchNeedleUsingRegex = [...jscriptExpression.match(regexFindBeweenBrackets)]
                let targetGuide = searchNeedleUsingRegex[0].substring(2, searchNeedleUsingRegex[0].length-2)
                let guideLanguage = self.getGuideLanguageById(targetGuide)
                return {
                    guideID: targetGuide,
                    link: pdfUsage ? `https://thrombosiscanada.ca/hcp/practice/clinical_guides?language=${guideLanguage}&guideID=${targetGuide}`
                        : `/hcp/practice/clinical_guides?language=${guideLanguage}&guideID=${targetGuide}`
                }
            }

            let self = this
            
            // First we need to find all occurences of the haystack in html
            const regexFindOpenLinkedGuideCalls = /href="javascript:openLinkedGuide[^>]+">/mg
            let rewritedHTML = html
            let m;

            while ((m = regexFindOpenLinkedGuideCalls.exec(rewritedHTML)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regexFindOpenLinkedGuideCalls.lastIndex) {
                    regexFindOpenLinkedGuideCalls.lastIndex++;
                }

                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                    if (match.length>0){
                        let replacementData = composeHrefLinkToReplaceWith(match)
                        rewritedHTML = rewritedHTML.replace(`javascript:openLinkedGuide('${replacementData.guideID}')`, replacementData.link)                        
                        //console.log(`Replace ${match} with ${replacementData.link}`)
                        //console.log(`Found match, group ${groupIndex}: ${match} , at position ${rewritedHTML.indexOf(match)}, `)
                    }
                });
            }
            
            return rewritedHTML
        },
        init: function(){
            this.initFacbook()
        },
        initFacbook: function(){


        },
        _header: function(pdf=false){
            let title = this.guide.name
            if(!pdf){
                return `<h1 class="guide-header"><strong>${title}</strong></h1>`
            }else{
                return `<div class="guide-header">
                        <div class="guide-title">
                            <h1><strong>${title}</strong></h1>
                        </div>
                        <div class="guide-logo">
                            <img src="https://s3.ca-central-1.amazonaws.com/thrombosiscanada.ca/uploads/images/logo.png"/>
                        </div>
                    </div>`
            }
        },
        correctImages: function(){
            // console.log('correctImages')
            let images = document.querySelectorAll('.clinicalGuide img')            
            for(let i=0; i<images.length; i++){
                let image = images[i]
                // console.log(image)
                // image.removeAttribute('width')
                // image.removeAttribute('height')
            }
        },
        track: async function(_resource){
            let data = {
                id: _resource.id,
                version: _resource.version,
                name: _resource.name.trim(),
                path: this.$route.path,
                log_key: _resource.name.trim(),
                tool: 'website',
                section: 'Clinical Guides'
            }
            this.sendRequest('put',`/api/tracker`, data)            
        },
        makePDF: function(){
            let guide = JSON.stringify(this.guide)
            guide = JSON.parse(guide)
            guide.html = `<html><body><div class="clinicalGuide">${this._header(true)} ${this.rewriteGuideLink(guide.html, true)}</div></body></html>`
            guide.html+=`
                <style type="text/css">
.guide-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 4px solid #EB2130;
    padding-bottom: 10px;
}
.guide-header div{
    width: 48%;
    align-self: flex-start;
}
.guide-header div:first-child{
    align-self: center;
}
.guide-header img{
    width: 100%;
}

h1{
    display: none;
}
.guide-title h1{
    display: inline-block !important;
}

a {
    color: #1976d2;
}
b, strong {
    font-weight: bolder;
}
.clinicalGuide{
    font-size: 14px;
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: Roboto,sans-serif;
    line-height: 1.5;
    padding: 15px;
}
.clinicalGuide h1 {
    font-size: 1.6em;
    margin-top: 0px;
    color: #293172;
    padding-bottom: 5px;
}
.clinicalGuide h2 {
    margin-top: 40px;
    font-size: 1.2em;
    color: #293172;
}

.clinicalGuide img{
    max-width: 100%;
}

table{
    border: 1px solid grey;
    border-collapse: collapse;
    width: 100%;
}

table th, table td{
    padding: 2px 5px;
    border: 1px solid grey;
    vertical-align: top;
}
</style>
            `
            this.$emit('makePDF',guide)
        },
        shareTwitter: function(){
            let path = this.$route.fullPath
            // let link = `http://twitter.com/share?url=https://thrombosiscanada.ca${path}`
            let link = `http://twitter.com/intent/tweet?url=https://thrombosiscanada.ca${path}`
            return link
        },
        shareFaceBook: function(){
            let path = this.$route.fullPath
            let link = `https://www.facebook.com/dialog/share?app_id=${this.$store.getters.fbid}&display=popup&href=https://thrombosiscanada.ca${path}`
            return link
        }
    },
    computed: {
        images: function(){
            return {
                logo: Logo
            }
        }
    }
}
</script>

<style lang="scss">
/* 
Make sure rules are in computed CSS: For PDF Styling
!!!NOT ALWAYS EXACTLY THE SAME !!!
---START--- 
*/
.guide-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 4px solid #EB2130;
}
.guide-header div{
    width: 48%;
    align-self: center;
}


.guide-topics{
    color: $primary;
    padding: 15px;
    margin-bottom: 25px;
    border-bottom: 1px solid $primary;
}

.clinicalGuide{
    padding: 15px 5px 15px 5px;
}

.clinicalGuide h1{
    font-size: 2em;
    margin-top: 0px;
    color: $primary;
    border-bottom: 4px solid $tc-red;
    padding-bottom: 5px;
}

.clinicalGuide h2{
    margin-top: 40px;
    font-size: 1.6em;
    color: $primary;

}

.clinicalGuide img{
    max-width: 100%;
    // max-height: 100%;

}

table{
    border: 1px solid grey;
    border-collapse: collapse;
    width: 100%;
}

table th, table td{
    padding: 2px 5px;
    border: 1px solid grey;
    vertical-align: top;
}
/* ---END--- */
.pin{
//   position: absolute;
//   top: 5px;
  right: 5px;
  background-color: $primary-1;
}

.pinned{
  background-color: $tc-red;
}

.share{
  background-color: #1d9bf0;
  margin: 5px;
}

.clinicalGuideViewer {
    a, p, li{
        word-break: break-word;
    }
    h1 {
        white-space: normal;
        word-wrap: break-word    
    }
    table {
        font-size: 0.7rem;
    }
}
</style>