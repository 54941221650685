<template>
<div class='wrap_pageCards'>
    <div v-for="(columnComponent, columnIndex) in columnComponents" :key="`${preKey}_${columnIndex}`">
        <component :is="columnComponent.component" :params="columnComponent.params" :language="language" @readMore="readMore"/>
    </div>
</div>


<!-- <v-container> -->
    <!-- <v-row dense justify="center">
        <v-col v-for="(columnComponent, columnIndex) in columnComponents" :key="`${preKey}_${columnIndex}`" :md="config.flex" :cols="12">
            <component :is="columnComponent.component" :params="columnComponent.params" :language="language" @readMore="readMore"/>
        </v-col>
    </v-row> -->
<!-- </v-container> -->
</template>

<script>
export default {
    props: {
        preKey: {
            type: String,
            required: true
        },
        columnComponents: {
            type: Array,
            required: true
        },
        language: {
          type: String,
          required: true
        }
    },
    data: function(){
        return {
            config: {
                flex: 4
            }
        }
    },
    methods: {
      readMore: function(data){
        this.$emit('readMore',data)
      }
    }
}
</script>

<style lang='scss'>

.wrap_pageCards {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 87%;
    margin: 0 auto;

    @media(max-width: 959px){
        display: block;
    }
    @media(max-width: 599px){
        display: flex;
        justify-content: center;
    }
}

</style>
