import { render, staticRenderFns } from "./AllowCookies.vue?vue&type=template&id=96bc84fe&scoped=true&"
import script from "./AllowCookies.vue?vue&type=script&lang=js&"
export * from "./AllowCookies.vue?vue&type=script&lang=js&"
import style0 from "./AllowCookies.vue?vue&type=style&index=0&id=96bc84fe&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96bc84fe",
  null
  
)

export default component.exports